<template>
  <a-drawer width="65%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <page-header-wrapper>
      <a-card :bordered="false">
        <!-- 增加修改 -->
        <create-form
          ref="createForm"
          @ok="getList"
        />
        <!-- 数据展示 -->
        <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :bordered="tableBordered"
        >
          <span slot="receiveTime" slot-scope="text, record">
            {{ parseTime(record.receiveTime) }}
          </span>
          <span slot="useTime" slot-scope="text, record">
            {{ parseTime(record.useTime) }}
          </span>
          <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </a-card>
    </page-header-wrapper>
  </a-drawer>
</template>

<script>
import { pageCouponUser,listCouponUser, delCouponUser } from '@/api/platform/couponUser'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'CouponUser',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      open: false,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        userId: null,
        couponId: null,
        receiveTime: null,
        useTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '狀態',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "未使用"
            }
            if (value == 1) {
              return "已使用"
            }
            if (value == 2) {
              return "已过期"
            }
          }
        },
        {
          title: '领取時間',
          dataIndex: 'receiveTime',
          scopedSlots: { customRender: 'receiveTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '使用時間',
          dataIndex: 'useTime',
          scopedSlots: { customRender: 'useTime' },
          ellipsis: true,
          align: 'center'
        },
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 修改按钮操作 */
    show (row) {
      console.log("row.couponId:"+row.id);
      this.queryParam.couponId = row.id;
      this.open = true
      this.getList()
    },
    /** 查詢优惠券-用戶领取使用列表 */
    getList () {
      this.loading = true
     pageCouponUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        couponId: undefined,
        receiveTime: undefined,
        useTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCouponUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/coupon-user/export', {
            ...that.queryParam
          }, `优惠券-用戶领取使用_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
