import request from '@/utils/request'


// 查询优惠券-用户领取使用列表
export function listCouponUser(query) {
  return request({
    url: '/platform/coupon-user/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券-用户领取使用分页
export function pageCouponUser(query) {
  return request({
    url: '/platform/coupon-user/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券-用户领取使用详细
export function getCouponUser(data) {
  return request({
    url: '/platform/coupon-user/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券-用户领取使用
export function addCouponUser(data) {
  return request({
    url: '/platform/coupon-user/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券-用户领取使用
export function updateCouponUser(data) {
  return request({
    url: '/platform/coupon-user/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券-用户领取使用
export function delCouponUser(data) {
  return request({
    url: '/platform/coupon-user/delete',
    method: 'post',
    data: data
  })
}
