import request from '@/utils/request'


// 查询优惠券-信息列表
export function listCoupon(query) {
  return request({
    url: '/platform/coupon/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券-信息分页
export function pageCoupon(query) {
  return request({
    url: '/platform/coupon/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券-信息详细
export function getCoupon(data) {
  return request({
    url: '/platform/coupon/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券-信息
export function addCoupon(data) {
  return request({
    url: '/platform/coupon/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券-信息
export function updateCoupon(data) {
  return request({
    url: '/platform/coupon/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券-信息
export function delCoupon(data) {
  return request({
    url: '/platform/coupon/delete',
    method: 'post',
    data: data
  })
}
